import en_GB from './en_GB.json'
import af_ZA from './af_ZA.json'
import fr_FR from './fr_FR.json'
import pt_PT from './pt_PT.json'
import zu_ZA from './zu_ZA.json'

export default {
  en_GB,
  af_ZA,
  fr_FR,
  pt_PT,
  zu_ZA,
} as const

export const defaultLocale = 'en_GB' as const

export const languageNames = {
  en_GB: 'English',
  af_ZA: 'Afrikaans',
  fr_FR: 'Français',
  pt_PT: 'Portuguesa',
  zu_ZA: 'IsiZulu',
} as const

export enum intlLocales {
  'en-GB' = 'en-GB',
  'af-ZA' = 'af-ZA',
  'fr-FR' = 'fr-FR',
  'pt-PT' = 'pt-PT',
  'za-ZA' = 'za-ZA',
}
