import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import 'animate.css/animate.css'
import { notification } from 'antd'
import ScreenSize from 'components/screen-size'
import useAxe from 'hooks/useAxe'
import { LocaleProvider } from 'i18n/locale-provider'
// import { loadIntercom } from 'lib/intercom'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import React, { useEffect, useState } from 'react'
import { isIE } from 'react-device-detect'
import 'styles/antd.css'
import 'styles/global.css'
import 'styles/nprogress.css'
import siteName from 'utils/siteName'

// cssVars()

const isDev = process.env.NODE_ENV === 'development'

// loadIntercom()

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

notification.config({
  placement: 'topRight',
})

const useViewHeight = () => {
  const [vh, setViewHeight] = useState<string>(
    typeof window !== 'undefined' && window?.innerHeight ? `${window.innerHeight * 0.01 * 100}px` : '100vh'
  )

  useEffect(() => {
    const handleSetViewHeight = () => {
      const height = window.innerHeight
      setViewHeight(`${height * 0.01 * 100}px`)
    }

    window.addEventListener('resize', () => handleSetViewHeight())
    return () => {
      window.removeEventListener('resize', () => handleSetViewHeight())
    }
  }, [])

  return vh
}

const Application = ({ Component, pageProps }: AppProps) => {
  const vh = useViewHeight()

  useAxe(false)

  return (
    <LocaleProvider entityDefaultLocale={pageProps.entityDefaultLocale} userLocale={pageProps.locale}>
      <Head>
        <title>{siteName('Pre-Screening')}</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        {/* <meta name="viewport" content="width=device-width,initial-scale=1" /> */}
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/*  <!-- Polyfills for IE 11 --> */}
      <Script src="/es6-shim.min.js" strategy="beforeInteractive" />
      <Component {...pageProps} />
      {isDev && <ScreenSize />}
      <style jsx global>{`
        :root {
          --vh: ${vh};
        }
        .toast.visible {
          -webkit-transform: ${isIE && 'translate3d(0, 0, -0px) scale(1) !important'};
          -ms-transform: ${isIE && 'translate3d(0, 0, -0px) scale(1) !important'};
          transform: ${isIE && 'translate3d(0, 0, -0px) scale(1) !important'};
        }
      `}</style>
    </LocaleProvider>
  )
}

export default Application
