// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import SentryRRWeb from '@sentry/rrweb'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // necessary for purely manual usage
  integrations: [
    ...(process.env.STAGING === 'yes'
      ? [
          new SentryRRWeb({
            // ...options
          }),
        ]
      : []),
  ],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // This enables automatic instrumentation (highly recommended), but is not
  // tunnel: '/api/sentry',
})

if (process.env.STAGING === 'yes') {
  Sentry.setTag('rrweb.active', 'yes')
}
