import React, { useEffect } from 'react'

export default function useAxe(enabled = process.env.NODE_ENV === 'development'): void {
  useEffect(() => {
    if (enabled) {
      const axe = require('@axe-core/react')
      const ReactDOM = require('react-dom')

      const timer = setInterval(() => {
        axe(React, ReactDOM, 1000, undefined, {
          // All page content must be contained by landmarks: use .axe-exclude class to skip axe checking
          exclude: [
            ['#__next-build-watcher'],
            ['#__next-prerender-indicator'],
            ['.axe-exclude'],
            ['.axe-ignore'],
            ['.ant-button.bg-primary', '.ant-button.ant-btn-primary'],
          ],
        })
      }, 1500)

      // clearing interval
      return () => clearInterval(timer)
    }
  })
}
