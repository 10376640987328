import messages, { intlLocales } from './locales'

export type Locale = keyof typeof intlLocales

export function parseLocale(locale: string) {
  return locale?.replace('_', '-') as Locale
}

export function getLocale(locale: MessageLocales | Locale) {
  return locale.split('_').shift().split('-').shift().toLowerCase()
}

export function getCountry(locale: MessageLocales | Locale) {
  return locale.split('_').pop().split('-').pop().toLowerCase()
}

export function isIntlLocale(tested: string): tested is Locale {
  return Object.keys(intlLocales).some((locale) => locale === tested)
}

export type MessageLocales = keyof typeof messages

export function parseMessageLocale(locale: string) {
  return locale?.replace('-', '_') as MessageLocales
}

export function isLocale(tested: string): tested is MessageLocales {
  return Object.keys(messages).some((locale) => locale === tested)
}

export function locale(lcl: MessageLocales | Locale) {
  return {
    country: getCountry(lcl),
    key: getLocale(lcl),
  }
}
