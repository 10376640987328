import Cookies from 'js-cookie'
import React, { useState, useRef } from 'react'
import { RawIntlProvider } from 'react-intl'
import { isLocale, MessageLocales, parseLocale, parseMessageLocale } from 'i18n/utils'
import getIntl from './config'
import { defaultLocale } from './locales'

type LocaleType = MessageLocales

interface ContextProps {
  readonly enabled: boolean
  readonly locale: LocaleType
  readonly setLocale: (locale: LocaleType) => void
  readonly setIntl: (intl: ReturnType<typeof getIntl>) => void
}

export const LocaleContext = React.createContext<ContextProps>({
  enabled: false, //globally disabled
  locale: 'en_GB',
  setLocale: () => null,
  setIntl: () => null,
})

interface LocaleProviderProps {
  userLocale: LocaleType
  entityDefaultLocale: LocaleType
}

export const LocaleProvider: React.FC<LocaleProviderProps> = (props) => {
  const initialUserLocale = parseMessageLocale(props.userLocale) // get locale from req.header | cookies | browser
  const initialEntityLocale = parseMessageLocale(props.entityDefaultLocale) // get locale from default entity location default locale
  const initialDefaultLocale = parseMessageLocale(defaultLocale) // fallback to default locale if all else fails
  const [locale, setLocale] = React.useState<LocaleType>(
    isLocale(initialUserLocale) ? initialUserLocale : isLocale(initialEntityLocale) ? initialEntityLocale : initialDefaultLocale
  )
  const [intl, setIntl] = useState(getIntl(locale))
  const mounted = useRef(false)

  React.useEffect(() => {
    mounted.current = true
    const setDocumentLocale = () => {
      if (process.browser && document && mounted.current) {
        const docHtml = document.getElementsByTagName('html')
        if (docHtml && docHtml[0]) docHtml[0].lang = parseLocale(locale || 'en-GB')
      }
    }
    if (locale !== Cookies.get('locale')) Cookies.set('locale', locale)
    setDocumentLocale()
    setIntl(getIntl(locale))
    return () => (mounted.current = false)
  }, [locale])

  return (
    <RawIntlProvider value={intl}>
      <LocaleContext.Provider value={{ enabled: false, locale, setLocale, setIntl }}>{props.children}</LocaleContext.Provider>
    </RawIntlProvider>
  )
}
