import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import { ConfigValueI18NText } from 'typings/kenai/interfaces'
import locales from './locales'
import { getLocale, MessageLocales, parseMessageLocale } from './utils'
const cache = createIntlCache()

const configI18NTextLocales: Record<string, Record<string, string>> = {}
let intl: IntlShape | undefined = undefined

export const setConfigI18NTexts = (configI18NTexts: ConfigValueI18NText[], defaultLocale: MessageLocales) => {
  configI18NTexts.forEach((configI18NText) => {
    const textLocales = Object.keys(configI18NText.texts) as MessageLocales[]
    textLocales.forEach((langKey) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!configI18NTextLocales.hasOwnProperty(langKey)) configI18NTextLocales[langKey] = {}
      configI18NTextLocales[langKey][configI18NText.textId] = configI18NText.texts[langKey]
    })
  })
  intl = createIntl(
    {
      locale: getLocale(defaultLocale),
      messages: {
        ...locales.en_GB, //default with en_GB
        ...locales[parseMessageLocale(defaultLocale)], //overwrite with any locale specific texts
        ...configI18NTextLocales['en_GB'], //default with en_GB config texts
        ...configI18NTextLocales[parseMessageLocale(defaultLocale)], //overwrite with any locale specific config texts
      },
    },
    cache
  )
  return intl
}

const getIntl = (requestedLocale?): IntlShape => {
  if (!intl) {
    intl = createIntl(
      {
        locale: getLocale('en-GB'),
        messages: {
          ...locales.en_GB,
          ...configI18NTextLocales['en_GB'], //default with en_GB config texts
        },
      },
      cache
    )
  }
  if (intl && requestedLocale && intl.locale !== requestedLocale) {
    intl = createIntl(
      {
        locale: getLocale(requestedLocale),
        messages: {
          ...locales.en_GB, //default with en_GB
          ...locales[parseMessageLocale(requestedLocale)], //overwrite with any locale specific texts
          ...configI18NTextLocales['en_GB'], //default with en_GB config texts
          ...configI18NTextLocales[parseMessageLocale(requestedLocale)], //overwrite with any locale specific config texts
        },
      },
      cache
    )
  }
  return intl
}

export default getIntl
